import { styled } from '@material-ui/core/styles';
import { compose, spacing, palette, typography } from '@material-ui/system';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)(
  compose(
    spacing,
    palette,
    typography
  ),
);

export default StyledButton;
