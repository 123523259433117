import { decorate, observable, action } from 'mobx';
import moment from 'moment';

const getNewExpiryDateFromNow = () => {
  // keep this in sync with backend session timeout
  // TODO use value from config instead of hardcoding here
  return moment().add(15, 'minutes');
};

class SessionStore {
  expiryDate = null;
  checker;

  start() {
    // set the session timout
    this.expiryDate = getNewExpiryDateFromNow();

    // check if session has timed out every few seconds
    // reload if timed out
    this.checker = setInterval(() => {
      if (moment().isAfter(this.expiryDate)) {
        this.stop();
        window.location.reload();
      }
    }, 1000 * 10);
  }

  stop() {
    clearInterval(this.checker);
    this.expiryDate = null;
  }

  resetExpiryDate() {
    // reset the session timeout if it already exists
    if (this.expiryDate !== null) {
      this.expiryDate = getNewExpiryDateFromNow();
    }
  }
}

decorate(SessionStore, {
  expiryDate: observable,
  checker: observable,
  start: action,
  stop: action,
  resetExpiryDate: action

});

export default new SessionStore();
