import { decorate, observable, action } from 'mobx';
import Ployglot from 'node-polyglot';

class LanguageStore {
  lang = null;
  langCode = null;
  langId = null;
  isFetchingLang = true;
  availableLanguages = null;
  siteLanguage = null;

  async initLanguage(availableLanguages, siteLanguage, languageCode) {
    this.availableLanguages = availableLanguages;
    this.siteLanguage = siteLanguage;
    this.setLanguage(languageCode);
  }

  async setLanguage(languageCode) {
    this.isFetchingLang = true;

    const languageDescriptor = this.availableLanguages.find(l => l['Language Code'] === languageCode);
    const languageName = languageDescriptor['Language Name'];
    const languagePack = {};
    this.siteLanguage.forEach(sl => {
      languagePack[sl['Phrase Key']] = sl[languageName];
    });

    this.langId = languageDescriptor['id'];
    this.langCode = languageCode;
    this.lang = new Ployglot({
      locale: languageCode,
      phrases: languagePack
    });

    this.isFetchingLang = false;
  }
}

decorate(LanguageStore, {
  lang: observable,
  langCode: observable,
  langId: observable,
  isFetchingLang: observable,
  initLanguage: action,
  setLanguage: action
});

export default new LanguageStore();
