import { decorate, observable, action } from 'mobx';
import Api from '../../api';
import sessionStore from '../session/SessionStore';
import languageStore from '../lang/LanguageStore';

class UserStore {
  user = null;
  isFetchingUser = true;
  isAuthenticated = false;

  setUser(user) {
    this.isFetchingUser = true;
    this.user = user;
    this.isAuthenticated = true;
    this.isFetchingUser = false;
  }

  setUserUnauthenticated() {
    this.isFetchingUser = true;
    this.user = null;
    this.isAuthenticated = false;
    this.isFetchingUser = false;
  }
}

decorate(UserStore, {
  user: observable,
  isFetchingUser: observable,
  isAuthenticated: observable,
  setUser: action
});

export default new UserStore();
