import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'mobx-react';

import asyncComponent from '../../util/asyncComponent';

// == Stores
// common
import userStore from '../../modules/user/UserStore';
import dataStore from '../../modules/data/DataStore';
import languageStore from '../../modules/lang/LanguageStore';
import firebaseStore from '../../modules/firebase/FirebaseStore';
import notificationStore from '../../modules/notification/NotificationStore';

// == Theme
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../styles/style-constants.js';

// Top Level Controllers (one for auth areas, may have multiple non-auth)
const CurateApp = asyncComponent(() => import(/* webpackChunkName: "app" */ './CurateApp'));
const Login = asyncComponent(() => import(/* webpackChunkName: "app" */ '../../containers/curate/Login'));

const Root = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider
        userStore={userStore}
        dataStore={dataStore}
        firebaseStore={firebaseStore}
        languageStore={languageStore}
        notificationStore={notificationStore}
      >
        <Router basename="/">
          <Switch>
            <Route
              path=""
              component={CurateApp}
            />
          </Switch>
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}

export default Root;
