// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu,
  MenuItem,
  Divider,
  Link
} from '@material-ui/core';

type Props = {
  menuEl: any,
  menuItems: Array,
  classes: Object
}

type State = {
  menuOpen: boolean
}

class Dropdown extends React.Component<Props, State> {
  anchorEl: ?HTMLElement;

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  handleOpenMenu = (event) => {
    this.anchorEl = event.currentTarget;
    this.setState({ menuOpen: true });
  }

  handleCloseMenu = () => {
    this.setState({ menuOpen: false });
  }

  renderMenuItems = () => {
    const { menuItems, selected, selectedIcon } = this.props;

    return menuItems.map((item, index) => {
      if (item.type === 'divider') {
        return (
          <Divider key={index} />
        );
      }

      if (item.type === 'header') {
        return (
          <MenuItem key={index} disabled>{item.display}</MenuItem>
        );
      }

      const handleItemClick = () => {
        if (item.onClick) {
          item.onClick();
        }
        this.handleCloseMenu();
      }

      let anchorProps = {};
      if (item.anchor) {
        anchorProps = {
          component: Link,
          href: `#${item.anchor}`
        }
      }

      return (
        <MenuItem onClick={handleItemClick} key={index} {...anchorProps}>
          {item.isSelected && selectedIcon && (selectedIcon)}&nbsp;
          {item.display}
        </MenuItem>
      );
    });
  };

  renderMenu = () => {
    return (
      <Menu
        anchorEl={this.anchorEl}
        open={this.state.menuOpen}
        onClose={this.handleCloseMenu}
      >
        {this.renderMenuItems()}
      </Menu>
    );
  }

  render() {
    const { menuEl } = this.props;

    return (
      <div>
        <div onClick={this.handleOpenMenu}>
          {menuEl}
        </div>
        {this.renderMenu()}
      </div>
    )
  }
};

const styles = {};

export default withStyles(styles)(Dropdown);
