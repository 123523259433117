import { styled } from '@material-ui/core/styles';
import { compose, spacing, palette, typography } from '@material-ui/system';
import { Typography } from '@material-ui/core';

const StyledTypography = styled(Typography)(
  compose(
    spacing,
    palette,
    typography
  ),
);

export default StyledTypography;
