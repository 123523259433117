import { decorate, observable, action, computed } from 'mobx';
import Api from '../../api';
import languageStore from '../lang/LanguageStore';
import config from '../../config';

const AIR_TABLES = [
  'Settings',
  'Site Language',
  'Pathways',
  'Topics',
  'Resources',
  'Available Languages'
];

const COMPANY_SETTINGS = {
  key: 'focus',
  name: 'Focus Consulting',
  projects: [
    {
      title: 'Demo Site',
      description: 'Demo Site for Learn Platform',
      key: 'demo'
    },
    {
      title: 'Remote Work Primer',
      description: 'A Set of resources for working from and sheltering from home.',
      key: 'remote'
    }
  ],
}

class DataStore {
  settings = null;
  siteLanguage = null;
  pathways = null;
  topics = null;
  resource = null;
  availableLanguages = null;
  isFetchingData = true
  isFetchingPublishHistory = false;
  isPublishing = false;

  async fetchData() {
    if (this.settings) {
      return;
    }

    try {
      this.isFetchingData = true;
      const data = await Api.getData(AIR_TABLES);
      this.settings = data['Settings'].find(envRow => envRow['Environment'] === config.airtableEnv);
      this.siteLanguage = data['Site Language'];
      this.pathways = data['Pathways'];
      this.topics = data['Topics'];
      this.resource = data['Resources'];
      this.availableLanguages = data['Available Languages'];

      // console.log('fetchData', data, this.settings, this.availableLanguages);

      await this.setInitialLanguage();
      this.isFetchingData = false;
    }
    catch (e) {
      this.isFetchingData = false;
    }
  }

  async setInitialLanguage() {
    const defaultLanguageId = this.settings['Default Language'][0];
    const defaultLanguageDescriptor = this.availableLanguages.find(al => al.id === defaultLanguageId);
    const languageCode = defaultLanguageDescriptor['Language Code'];
    await languageStore.initLanguage(this.availableLanguages, this.siteLanguage, languageCode);
  }

  getCompanySettings(companyKey) {
    return COMPANY_SETTINGS;
  }

  async getPublishHistory(projectKey, env) {
    this.isFetchingPublishHistory = true;
    const publishHistory = await Api.getPublishHistory(COMPANY_SETTINGS.key, projectKey, env);
    this.isFetchingPublishHistory = false;
    return publishHistory;
  }

  async publish(projectKey, env, message) {
    this.isPublishing = true;
    await Api.publish(COMPANY_SETTINGS.key, projectKey, env, message);
    this.isPublishing = false;
    this.getPublishHistory(projectKey, env);
  }
}

decorate(DataStore, {
  settings: observable,
  siteLanguage: observable,
  pathways: observable,
  topics: observable,
  resource: observable,
  availableLanguages: observable,
  isFetchingData: observable,
  isFetchingPublishHistory: observable,
  isPublishing: observable,
  fetchData: action
});

export default new DataStore();
