import {createMuiTheme} from '@material-ui/core/styles';

// COLORS
export const primaryColor = '#232946';
export const primaryColorLight = '#232946';
//
// export const secondaryColor = '#FF9A4A';
// export const secondaryColorLight = '#FDAE75';

// SIZES
export const phoneScreenMaxWidth = '767px';
export const largeScreenMinWidth = '992px';
export const extraLargeScreenMinWidth = '1441px';
export const remBase = 16;

// Customize the default theme
// https://material-ui.com/customization/default-theme/
export const theme = createMuiTheme({
  typography: {
    // fontFamily: ['proxima-nova', 'Open Sans', 'Arial']
    fontFamily: ['Inter', 'Open Sans', 'Arial']
    // fontFamily: ['Open Sans', 'Arial'], // default is Roboto
  },
  palette: {
    primary: {
      main: primaryColor,
      light: primaryColorLight,
      dark: primaryColor,
      contrastText: '#fff',
    }
  //   secondary: {
  //     main: secondaryColor,
  //     light: secondaryColorLight,
  //     dark: secondaryColor,
  //     contrastText: '#fff',
  //   },
  },
});
