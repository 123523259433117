import { decorate, observable, action } from 'mobx';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import userStore from '../user/UserStore';
import dataStore from '../data/DataStore';

class FirebaseStore {
  isInitializingFirebase = true;
  db = null;

  init() {
    // if (firebase.apps.length > 0) {
    // https://github.com/zeit/next.js/issues/1999
    //   return;
    // }

    if (this.db !== null) {
      return;
    }

    console.log('initializing firebase');
    this.isInitializingFirebase = true;
    firebase.initializeApp({
      apiKey: "AIzaSyDvkWly5Vop9f5JhNktiQQP0lonA34wJEw",
      authDomain: "learn-67acf.firebaseapp.com",
      databaseURL: "https://learn-67acf.firebaseio.com",
      projectId: "learn-67acf",
      storageBucket: "learn-67acf.appspot.com",
      messagingSenderId: "244304246184",
      appId: "1:244304246184:web:715f2fd8e10c1fcacbd99d",
      measurementId: "G-C915GRD8FF"
    });

    // this.auth = firebase().auth;
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log('user is authenticated', user);
        userStore.setUser(user);
      }
      else {
        console.log('user is not authenticated')
        userStore.setUserUnauthenticated();
      }
    })

    // set the db, initialize data
    this.db = firebase.firestore();
    dataStore.fetchData();

    this.isInitializingFirebase = false;
  }

  signOut = () => {
    firebase.auth().signOut()
  }
}

decorate(FirebaseStore, {
  isInitializingFirebase: observable,
  db: observable,
  init: action
});

export default new FirebaseStore();
