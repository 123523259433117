import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import './styles/global.css';
import Root from './core/Root';
import CurateRoot from './core/curate/CurateRoot'
import MarketingHome from './core/marketing/MarketingHome';

const product = process.env.REACT_APP_PRODUCT_NAME;

const getRoot = () => {
  switch (product) {
    case 'marketing':
      return <MarketingHome />
      break;
    case 'curate':
      return <CurateRoot />
    default:
      return <Root />
  }
}

ReactDOM.render(getRoot(), document.getElementById('root'));
