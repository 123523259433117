// Import all environment config files here
import local from './local';
import staging from './staging';
import prod from './prod';

// Map to environment name
const envToConfig = {
  local,
  staging,
  prod
};

const env = process.env.REACT_APP_BUILD_ENV; // eslint-disable-line no-undef
console.log('env 2', process.env.PORT)
const envName = env ? env : 'local';
const envConfig = envToConfig[envName];

const defaultsConfigs = {
  useMockApi: false,
  apiUrl: 'http://localhost:5200/api',
};

const config = {
  ...defaultsConfigs,
  ...envConfig,
  isLocal: env === 'local',
  isDev: env === 'dev',
  isProd: env === 'prod',
};

export default config;
