import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { Grid, Box, Link, Hidden } from '@material-ui/core';
import Typography from '../../components/common/StyledTypography';
import Button from '../../components/common/StyledButton';
import Dropdown from '../../components/common/Dropdown';
import Carousel from 'react-material-ui-carousel';
import MenuIcon from '@material-ui/icons/Menu';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import { theme } from '../../styles/style-constants.js';

const STYLE_GUIDES = {
  'brown': {
    xPadding: 25,
    yPadding: 7,
    cardPadding: 5,
    sections: [
      {
        bgcolor: '#f9f4ef',
        header: '#020826',
        sub: '#716040'
      },
      {
        bgcolor: '#fffffe',
        header: '#020826',
        sub: '#716040',
        cardBgColor: '#eaddcf',
        cardHeader: '#020826',
        cardBodyColor: '#716040',
      },
      {
        bgcolor: '#f9f4ef',
        header: '#020826',
        sub: '#716040',
        cardBgColor: '#fffffe',
        cardHeader: '#020826',
        cardBodyColor: '#716040',
        cardHighlightColor: '#f25042'
      },
    ]
  },
  'blue': {
    xPadding: 25,
    yPadding: 7,
    cardPadding: 5,
    sections: [
      {
        bgcolor: '#232946',
        header: '#fffffe',
        sub: '#b8c1ec'
      },
      {
        bgcolor: '#fffffe',
        header: '#232946',
        sub: '#232946',
        cardBgColor: '#d4d8f0',
        cardHeader: '#232946',
        cardBodyColor: '#232946',
      },
      {
        bgcolor: '#232946',
        header: '#fffffe',
        sub: '#b8c1ec',
        cardBgColor: '#fffffe',
        cardHeader: '#232946',
        cardBodyColor: '#232946',
        cardHighlightColor: '#d4939d'
      },
    ]
  },
  'grey': {
    xPadding: 25,
    yPadding: 7,
    cardPadding: 5,
    sections: [
      {
        bgcolor: '#eff0f3',
        header: '#0d0d0d',
        sub: '#2a2a2a'
      },
      {
        bgcolor: '#fffffe',
        header: '#0d0d0d',
        sub: '#2a2a2a',
        cardBgColor: '#eff0f3',
        cardHeader: '#0d0d0d',
        cardBodyColor: '#2a2a2a',
      },
      {
        bgcolor: '#eff0f3',
        header: '#0d0d0d',
        sub: '#2a2a2a',
        cardBgColor: '#fffffe',
        cardHeader: '#0d0d0d',
        cardBodyColor: '#2a2a2a',
        cardHighlightColor: '#0d0d0d'
      },
    ]
  },
  'mix': {
    xPadding: { xs: 2, sm: 5, md: 25 },
    yPadding: 7,
    cardPadding: { xs: 3, sm: 4.5 },
    sections: [
      {
        bgcolor: '#eff0f3',
        header: '#0d0d0d',
        sub: '#2a2a2a'
      },
      {
        bgcolor: '#fffffe',
        header: '#232946',
        sub: '#232946',
        cardBgColor: '#d4d8f0',
        cardHeader: '#232946',
        cardBodyColor: '#232946',
      },
      {
        bgcolor: '#232946',
        header: '#fffffe',
        sub: '#b8c1ec',
        cardBgColor: '#fffffe',
        cardHeader: '#232946',
        cardBodyColor: '#232946',
        cardHighlightColor: '#d4939d'
      },
    ]
  }
}

const Marketing = ({ classes }) => {

  const sg = STYLE_GUIDES['mix'];

  const renderHeader = () => {
    const sectionIndex = 0;
    const sectionStyle = sg.sections[sectionIndex];


    const menuItems = [
      { display: 'How It Works', anchor: 'how' },
      { display: 'Use Cases', anchor: 'usecase' },
      { display: 'Showcase', anchor: 'showcase' },
      { display: 'Pricing', anchor: 'pricing' },
      { display: 'Get Started', anchor: 'start' }
    ];

    return (
      <Box
        bgcolor={sectionStyle.bgcolor}
        px={sg.xPadding}
        py={3}
      >
        <Grid container>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <img style={{ maxHeight: 30 }} src="https://firebasestorage.googleapis.com/v0/b/learn-67acf.appspot.com/o/assets%2Flearn-red-logo.png?alt=media&token=cfc8d0f4-ae66-4b1b-a8e9-7083b17054ad"/>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex',
          justifyContent: 'flex-end'}}>
            <Hidden mdUp>
              <Dropdown
                menuEl={(<Button><MenuIcon /></Button>)}
                menuItems={menuItems}
              />
            </Hidden>
            <Hidden mdDown>
              <Box display="flex">
              {menuItems.map(link => {
                return (
                  <Link href={`#${link.anchor}`} className={classes.link}>
                    <Typography variant="body1" fontWeight={600} ml={2.5} color={sectionStyle.header}>{link.display}</Typography>
                  </Link>
                )
              })}
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const renderHero = () => {
    const sectionIndex = 0;

    return (
      <Box
        bgcolor={sg.sections[sectionIndex].bgcolor}
        px={sg.xPadding}
        py={sg.yPadding}
        textAlign={"center"}
      >
        <Grid container alignItems="center" spacing={8}>
          <Grid item md={6} xs={12}>
            <Box>
              <Typography variant="h3" mb={2} fontWeight={600}   color={sg.sections[sectionIndex].header}>
                Launch your next learning site in days.
              </Typography>
              <Typography variant="h6" color={sg.sections[sectionIndex].sub}>
                Learn is an easy content curation and publishing tool for your company's learning and development needs.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <img style={{ width: '100%' }} src="https://firebasestorage.googleapis.com/v0/b/learn-67acf.appspot.com/o/assets%2Fwoman-with-laptop-orange.png?alt=media&token=c39c751c-5e54-4da0-a51b-4f5bbc237dd9" />
          </Grid>
        </Grid>
      </Box>
    );
  }

  const Card = ({ sectionStyle, card }) => {
    return (
      <Box
        bgcolor={sectionStyle.cardBgColor}
        p={sg.cardPadding}
        textAlign={'left'}
        borderRadius={3}
      >
        <Typography variant="h4" mb={1} fontWeight={600} color={sectionStyle.cardHeader}>
          {card.title}
        </Typography>
        <Typography variant="h6" mb={2} color={sectionStyle.cardBodyColor}>
          {card.subtitle}
        </Typography>
        <Box color={sectionStyle.cardBodyColor}>
          {card.items.map(item => (
            <Typography variant="body1" fontWeight={600} mb={.7}>{item.text}</Typography>
          ))}
        </Box>
      </Box>
    )
  }

  const renderHowToSection = () => {
    const sectionIndex = 1;
    const sectionStyle = sg.sections[sectionIndex];

    const cards = [
        {
          title: 'Setup',
          subtitle: 'We’ll help quickly setup your site.',
          items: [
            { text: 'Custom domain' },
            { text: 'Language setup' },
            { text: 'Email/password, social or SSO login' },
            { text: 'Brand with logos, favicons and colors' }
          ]
        },
        {
          title: 'Curate',
          subtitle: 'Use our intuitive content curation tools.',
          items: [
            { text: 'Site level feature toggles' },
            { text: 'Curate into three levels' },
            { text: 'Translation for all your languages' },
            { text: 'Bulk import existing content' }
          ]
        },
        {
          title: 'Publish',
          subtitle: 'Go live with updates in seconds.',
          items: [
            { text: 'Test updates on staging server' },
            { text: 'Mobile first, accessible sites' },
            { text: 'Hosted on Google Cloud services' },
            { text: 'Built-in Monitoring and Analytics' },
          ]
        },
        {
          title: 'Support',
          subtitle: 'Multi-channel support.',
          items: [
            { text: 'Chat and email support' },
            { text: 'How to Guides for all our features' },
            { text: 'Release Notes and Public Roadmap' },
            { text: 'More with our consulting services' }
          ]
        }
    ];

    const carouselItems = [
      {
        title: 'Publish your content',
        src: 'https://firebasestorage.googleapis.com/v0/b/learn-67acf.appspot.com/o/assets%2Fcurate-screenshot.png?alt=media&token=1fab3415-900e-4b28-be7b-8da13c3d322d'
      },
      {
        title: 'Your changes are up in seconds',
        src: 'https://firebasestorage.googleapis.com/v0/b/learn-67acf.appspot.com/o/assets%2Flearn-screeshot.png?alt=media&token=faa5d61f-982d-4361-9ea5-b7a3cec22b06'
      }
    ]

    return (
      <Box
        bgcolor={sectionStyle.bgcolor}
        px={sg.xPadding}
        py={sg.yPadding}
        textAlign={"center"}
        id="how"
      >
        <Typography variant="h3" mb={2} fontWeight={600} color={sectionStyle.header}>
          How It Works
        </Typography>
        <Typography variant="h6" mb={4} color={sectionStyle.sub}>
          Easy site configuraiton, authoring and publishing tools allow you to quickly setup and go live.
        </Typography>
        <Grid container spacing={4}>
            {cards.map(card => (
              <Grid item xs={12} md={6}>
                <Card
                  sectionStyle={sectionStyle}
                  card={card}
                />
              </Grid>
            ))}
        </Grid>
        <Box mt={4} p={2} borderColor={sectionStyle.cardBgColor} border={2} borderRadius={3}>
          <Carousel indicators={false}>
            {carouselItems.map(item => {
              return (
                <Box>
                  <Typography variant="h6" mb={4} borderBottom={2}>{item.title}</Typography>
                  <img style={{ width: '100%' }} src={item.src}/>
                </Box>
              )
            })}
          </Carousel>
        </Box>
      </Box>
    )
  }

  const renderUseCasesSection = () => {
    const sectionIndex = 2;
    const sectionStyle = sg.sections[sectionIndex];

    const cards = [
        {
          title: 'Small Business',
          subtitle: 'Organize learning around your business.',
          items: [
            { text: 'Company Learning Resources' },
            { text: 'Event Specific Resources' },
            { text: 'Career Pathway Learning Goals' },
          ]
        },
        {
          title: 'Enterprise',
          subtitle: 'Create portals across your org and beyond.',
          items: [
            { text: 'Client / Customer Learning Portal' },
            { text: 'Compliance and Training Portals' },
            { text: 'Career Pathway Learning Goals' },
          ]
        },
        {
          title: 'Education',
          subtitle: 'Curate learning acorss audiences.',
          items: [
            { text: 'Companion learning portal for a class' },
            { text: 'Resources for Scheduled Events' },
            { text: 'Curated Department Resources' },
          ]
        },
        {
          title: 'Government',
          subtitle: 'Resources for civil servants and citizens.',
          items: [
            { text: 'Learning Resources for Civil Servants' },
            { text: 'Resources for Scheduled Events' },
            { text: 'Curated Resources by Department' }
          ]
        }
    ];

    return (
      <Box
        bgcolor={sectionStyle.bgcolor}
        px={sg.xPadding}
        py={sg.yPadding}
        textAlign={"center"}
        id="usecase"
      >
        <Typography variant="h3" mb={2} fontWeight={600} color={sectionStyle.header}>
          Use Cases
        </Typography>
        <Typography variant="h6" mb={4} color={sectionStyle.sub}>
          Learn can be used to build for a variety of use cases and audiences.
        </Typography>
        <Grid container spacing={7}>
            {cards.map(card => (
              <Grid item xs={12} md={6}>
                <Card
                  sectionStyle={sectionStyle}
                  card={card}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    )
  }

  const renderShowcasesSection = () => {
    const sectionIndex = 1;
    const sectionStyle = sg.sections[sectionIndex];

    const airtables = [
      {
        title: 'Multiple Languages Setup',
        src: 'https://airtable.com/embed/shrecMGhAmdN7XS2v?backgroundColor=orange'
      },
      {
        title: 'Site Settings',
        src: 'https://airtable.com/embed/shrfBVtIUAGPY140s?backgroundColor=orange'
      },
      {
        title: 'Site language translations',
        src: 'https://airtable.com/embed/shrH0kumqaFrkBmjk?backgroundColor=orange'
      },
      {
        title: 'Modules',
        src: 'https://airtable.com/embed/shrgpQBiWOspOyqP9?backgroundColor=orange'
      }
    ]

    return (
      <Box
        bgcolor={sectionStyle.bgcolor}
        px={sg.xPadding}
        py={sg.yPadding}
        textAlign={"center"}
        id="showcase"
      >
        <Typography variant="h3" mb={2} fontWeight={600} color={sectionStyle.header}>
          Showcase
        </Typography>
        <Typography variant="h4" color={sectionStyle.sub} mb={3}>
          <Link href="https://learn-67acf.web.app" target="_blank" style={{ color: 'inherit', textDecoration: 'underline' }}>
            See Sample Site
          </Link>
        </Typography>
        <Typography variant="h6" mb={4} color={sectionStyle.sub}>
          See how the content is organized below (using our optional Airtable integration).
        </Typography>
        <Box mt={2} p={2} borderColor={sectionStyle.cardBgColor} border={2} borderRadius={3}>
          <Carousel indicators={false}>
            {airtables.map(item => {
              return (
                <Box>
                  <Typography variant="h6" mb={4} borderBottom={2}>{item.title}</Typography>
                  <iframe className={'airtable-embed'} src={item.src} frameborder="0" onmousewheel="" width="100%" height="533" style={{ background: 'transparent; border: 1px solid #ccc'}}></iframe>
                </Box>
              )
            })}
          </Carousel>
        </Box>
      </Box>
    )
  }

  const renderPricingSection = () => {
    const sectionIndex = 2;
    const sectionStyle = sg.sections[sectionIndex];

    const cards = [
        {
          title: 'Starter Plan',
          subtitle: '$1500/month',
          items: [
            { text: 'Login Options: Email, Social Logins' },
            { text: 'Upto 3 sites' },
            { text: 'Only logged in sites' },
            { text: 'One time setup fee: $500' },
          ]
        },
        {
          title: 'Business Plan',
          subtitle: '$5000/month',
          items: [
            { text: 'Login Options: Starter + SSO Login' },
            { text: 'Upto 10 Sites' },
            { text: 'Logged in or public sites' },
            { text: 'One time setup fee: $1500' },
          ]
        },
        {
          title: 'Enterprise Plan',
          subtitle: 'Let\'s Chat',
          items: [
            { text: 'Fill out the form below so we understand your needs better. We\'ll be in contact soon to discuss how we can support your business.' }
          ]
        },
        {
          title: 'Need More?',
          subtitle: 'Use our consulting services.',
          items: [
            { text: 'Bulk content import' },
            { text: 'Custom template design' },
            { text: 'Learning content design' }
          ]
        }
    ];

    return (
      <Box
        bgcolor={sectionStyle.bgcolor}
        px={sg.xPadding}
        py={sg.yPadding}
        textAlign={"center"}
        id="pricing"
      >
        <Typography variant="h3" mb={2} fontWeight={600} color={sectionStyle.header}>
          Pricing
        </Typography>
        <Typography variant="h6" mb={4} color={sectionStyle.sub}>
          All plans include: custom domains, content curation and publishing, multi-language support, analytics and monitoring, staging servers, file storage, hosting with scaling and backups.
        </Typography>
        <Grid container spacing={7}>
            {cards.map(card => (
              <Grid item xs={12} md={6}>
                <Card
                  sectionStyle={sectionStyle}
                  card={card}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    )
  }

  const renderGetStarted = () => {
    const sectionIndex = 1;
    const sectionStyle = sg.sections[sectionIndex];

    // <Typography variant="h6" mb={4} color={sectionStyle.sub}>
    //   Tell us a bit about your needs and we'll be in contact shortly.
    // </Typography>
    return (
      <Box
        bgcolor={sectionStyle.bgcolor}
        px={sg.xPadding}
        py={sg.yPadding}
        textAlign={"center"}
        id="start"
      >
        <Typography variant="h3" mb={2} fontWeight={600} color={sectionStyle.header}>
          Let's Get Started
        </Typography>
        <Box mt={2} p={2} borderColor={sectionStyle.cardBgColor} border={2} borderRadius={3}>
          <iframe className="airtable-embed" src="https://airtable.com/embed/shrdpJai1PuHDROzn?backgroundColor=green" frameborder="0" onmousewheel="" width="100%" height="1100" style={{ background: 'transparent; border: 1px solid #ccc;'}}></iframe>
        </Box>
      </Box>
    )
  }

  const renderFooter = () => {
    const sectionIndex = 0;
    const sectionStyle = sg.sections[sectionIndex];

    const renderFooterLink = (text, url) => {
      return (
        <Typography mb={1} display="flex">
          <Link className={classes.link} href={url} target="_blank">{text}</Link>
        </Typography>
      );
    }

    const SUPPORT_PAGE = 'https://www.notion.so/Learn-Help-Center-660e8f81da2f4832afa031b44bd6e449';
    const PUBLIC_ROADMAP = 'https://www.notion.so/Public-Roadmap-66d1eab2cc0b410f83cccef58c4dc4fd';
    const NEWSLETTER = 'https://tinyletter.com/focusconsulting';
    const FOCUS_CONSULTING = 'https://focusconsulting.io';

    return (
      <Box
        bgcolor={sectionStyle.bgcolor}
        px={sg.xPadding}
        py={sg.yPadding * 2}
        textAlign={"center"}
        id="start"
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Typography mb={2} display="flex">Learn is a product of {renderFooterLink('Focus Consulting', FOCUS_CONSULTING)}</Typography>
            {renderFooterLink('Terms', SUPPORT_PAGE)}
            {renderFooterLink('Privacy', SUPPORT_PAGE)}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderFooterLink('Contact Support', SUPPORT_PAGE)}
            {renderFooterLink('How To Guides', SUPPORT_PAGE)}
            {renderFooterLink('Release Notes', SUPPORT_PAGE)}
            {renderFooterLink('Public Roadmap', PUBLIC_ROADMAP)}
            {renderFooterLink('Newsletter', NEWSLETTER)}
          </Grid>
          <Grid item xs={12}>
            <Typography>©2020 Focus Consulting LLC.</Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
    <Box className="nomargin">
      <Helmet>
        <title>Learn by Focus Consulting</title>
      </Helmet>
      {renderHeader()}
      {renderHero()}
      {renderHowToSection()}
      {renderUseCasesSection()}
      {renderShowcasesSection()}
      {renderPricingSection()}
      {renderGetStarted()}
      {renderFooter()}
    </Box>
    </MuiThemeProvider>
  )
}

const styles = theme => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      // textDecoration: 'none',
    }
  }
});

export default withStyles(styles)(Marketing);
